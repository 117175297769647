<template>
    <TheLayoutWrap v-if="loaded">
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
    </TheLayoutWrap>
    <AppSpinner v-else :loading="true"/>
</template>

<script>

import TheLayoutWrap from "@/components/Layout/TheLayoutWrap.vue"
import AppSpinner from "@/components/Base/AppSpinner.vue";

export default {
  name: 'AppContent',
  data: () => ({
    loaded: false
  }),
  components: {
    TheLayoutWrap,
    AppSpinner
  },
  created() {
    this.$store.dispatch('auth/checkUserToken')
      .then(() => {
        this.loaded = true;
      })
  }
}
</script>
