<template>
    <form class="login-form md-elevation-5" @keyup.enter="login()">

        <img srcset="@/assets/img/tcs_logo_1x.png 1x, @/assets/img/tcs_logo_2x.png 2x"
             alt="Logo Image"
             class="login-form__logo">

        <h1 class="md-title login-form__title">Welcome back!</h1>

        <AppInput
                class="login-form__field"
                required
                placeholder="Email"
                v-model="$v.email.$model"
                :error="$v.email.$invalid && $v.email.$dirty"
                errorText="Valid email is required"
        />

        <AppInput
                required
                class="login-form__field"
                type="password"
                placeholder="Password"
                v-model="$v.password.$model"
                :error="$v.password.$invalid && $v.password.$dirty"
        />
        <md-button
                id="submit"
                class="md-primary md-raised login-form__submit-btn"
                :disabled="$v.$invalid"
                @click="login()">Submit</md-button>

        <a class="login__forgot-link md-body-1" style="color: black; cursor: pointer" @click.prevent="showResetDialog()">Forgot your password?</a>
        <AppSpinner v-if="loading" :loading="loading"/>
        <ResetPasswordDialog
                :showDialog.sync="showDialog"
                :emailProp="email"
                :key="dialogKey"
                @emailRequested="showSnackbar('Email successfully requested!', 3000)"
        />

        <md-snackbar 
            md-position="center"
            :md-active.sync="snackbar.show"
            :md-duration="snackbar.duration"
            md-persistent>
            <span>{{snackbar.message}}</span>
            <md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>
        </md-snackbar>

    </form>
</template>

<script>
    import {required, email} from 'vuelidate/lib/validators'
    import ResetPasswordDialog from '@/components/Login/ResetPasswordDialog'

    export default {
        name: 'AppLogin',
        data: () => ({
            email: '',
            password: '',
            showDialog: false,
            dialogKey: 0,
            snackbar: {
                show: false,
                message: '',
                duration: 4000
            },
            loading: false,
        }),
        validations: {
            email: {required, email},
            password: {required}
        },
        components: {
            ResetPasswordDialog
        },
        methods: {
            login() {
                this.loading = true
                this.$store.dispatch('auth/signIn', {
                    email: this.email,
                    password: this.password
                })
                .then(() => {
                    this.$router.replace('/design');
                })
                .catch(() => {
                    this.showSnackbar('Failed. Check your credentials, please.', 8000);
                })
                .finally(() => {
                  this.loading = false
                })
            },

            showResetDialog() {
                this.showDialog = true;
                this.dialogKey++;
            },

            showSnackbar(message, duration) {
                this.snackbar.message = message;
                this.snackbar.duration = duration;
                this.snackbar.show = true;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .login-form {
        @include default-auth-form;
    }
</style>
