<template>
    <md-menu md-size="medium" md-align-trigger>

        <md-button @click.stop.prevent md-menu-trigger class="md-raised md-primary btn--narrow">
            <md-icon>more_vert</md-icon>
        </md-button>

        <md-menu-content>
            <md-menu-item
                @click="changeOrderStatus(statuses.sponsorship.DOWNLOADED_BY_PRINTER, statuses.signage.PARTY_APPROVED)">
                As downloaded</md-menu-item>
            
            <md-menu-item
                @click="changeOrderStatus(statuses.sponsorship.READY_TO_PRINT, statuses.signage.READY_TO_PRINT)">
                As ready to print</md-menu-item>
            
            <md-menu-item
                @click="changeOrderStatus(statuses.sponsorship.READY_TO_INSTALL, statuses.signage.PRINTED)">
                As ready to install</md-menu-item>
            
            <md-menu-item
                @click="changeOrderStatus(statuses.sponsorship.INSTALLED, statuses.signage.INSTALLED)">
                As installed</md-menu-item>
            
            <md-menu-item
                @click="changeOrderStatus(statuses.sponsorship.REMOVED, statuses.signage.REMOVED)">
                As removed</md-menu-item>

            <md-menu-item
                    @click="changeOrderStatus(statuses.sponsorship.PRINTED, statuses.signage.PRINTED)">
                As printed</md-menu-item>

            <md-menu-item
                    @click="changeOrderStatus(statuses.sponsorship.PRINTED_CORRECT_READY_TO_LOAD, statuses.signage.PRINTED_CORRECT_READY_TO_LOAD)">
                As printed, correct, ready to load</md-menu-item>

            <md-menu-item
                    @click="changeOrderStatus(statuses.sponsorship.READY_FOR_PUBLICATION, statuses.signage.READY_FOR_PUBLICATION)">
                As Ready for publication</md-menu-item>

            <md-menu-item
                    @click="changeOrderStatus(statuses.sponsorship.PACKING, statuses.signage.PACKING)">
                As Packing</md-menu-item>

            <md-menu-item
                    @click="changeOrderStatus(statuses.sponsorship.READY_TO_SHIP, statuses.signage.READY_TO_SHIP)">
                As Ready to ship</md-menu-item>

            <md-menu-item
                    @click="changeOrderStatus(statuses.sponsorship.SHIPPED, statuses.signage.SHIPPED)">
                As Shipped</md-menu-item>

            <md-menu-item
                    @click="changeOrderStatus(statuses.sponsorship.IN_STAGING, statuses.signage.IN_STAGING)">
                As In Staging</md-menu-item>

            <md-menu-item
                    @click="changeOrderStatus(statuses.sponsorship.UNPACKED, statuses.signage.UNPACKED)">
                As Unpacked</md-menu-item>

            <md-menu-item
                    @click="changeOrderStatus(statuses.sponsorship.WALKTHROUGH_COMPLETED, statuses.signage.WALKTHROUGH_COMPLETED)">
                As Walkthrough Completed</md-menu-item>

            <md-menu-item
                    @click="changeOrderStatus(statuses.sponsorship.SOLVED, statuses.signage.SOLVED)">
                As Solved</md-menu-item>

            <md-menu-item
                    @click="changeOrderStatus(statuses.sponsorship.CANCELLED, statuses.signage.CANCELLED)">
                As Cancelled</md-menu-item>

            <md-menu-item
                    @click="changeOrderStatus(statuses.sponsorship.PROBLEM, statuses.signage.PROBLEM)">
                As Print Problem</md-menu-item>

            <md-menu-item
                    @click="changeOrderStatus(statuses.sponsorship.INSTALLATION_PROBLEM, statuses.signage.INSTALLATION_PROBLEM)">
                As Installation Problem</md-menu-item>

        </md-menu-content>

    </md-menu>
</template>

<script>
import { sponsorshipStatuses, signageStatuses } from '../../constants';

export default {
    name: 'OrderCardStatusesMenu',
    props: {
        order: {
            type: Object,
            required: true
        },
    },

    data: function() {
        return {
            statuses: {
                sponsorship: sponsorshipStatuses,
                signage: signageStatuses
            }
        }
    },

    methods: {

      changeOrderStatus(sponsorshipStatus, signageStatus) {
        const isSponsorship = this.order.media_name === 'Sponsorship';
        const payload = isSponsorship
            ? { sponsorship_status: sponsorshipStatus }
            : { status_id: signageStatus };

        const url = isSponsorship
            ? `/sponsorships/${this.order.sponsorships_id}`
            : `/signage-orders/${this.order.id}`;

        this.$axios.put(url, payload)
            .then(() => this.$emit('statusUpdated'))
            .catch(console.log);
      },
      changeOrderStatus_old(sponsorshipStatus, signageStatus) {
            let payload;

            if (this.order.mediaType === 'sponsorship') {
                payload = {
                    sponsorship_status: sponsorshipStatus
                };
            } else {
                payload = {
                    status_id: signageStatus
                }
            }

            this.order.updateOrder(payload)
                .then(() => {
                    this.$emit('statusUpdated');
                })
                .catch(err => {
                    console.log('failedToUpdateStatus', err.message);
                })
        }
    }
}
</script>


<style lang="scss" scoped>
.md-menu {
    margin-left: 5px;
    
    @include respond(xsmall) { 
        margin: 0;
    }
}
 /deep/  .btn--narrow {
    padding: 0;
    width: 40px;
    min-width: 40px;

    @include respond(xsmall) { 
        width: 160px;
        margin: 8px 8px 0 0;
    }

    .md-ripple {
        padding: 0;
    }
}
</style>